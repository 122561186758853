@tailwind base;
@tailwind components;
@tailwind utilities;

/* Grande parte desses estilos aqui estão defasados pq foram montados para uma versão antigo do front que não usava MUI */
/* Recomendo ir migrando para os estilos que ficam em theme.js para usar cores gloabais no sistema e tals */
/* E tbm é melhor ir movendo os estilos para os compoentes pra poder usar como 'variant' */
/* No componente de botão tem um exemplo bom */

:root {
  --marrom-couro: #482B26;
  --marrom-popup: #F5D4BF;
  --marrom-popup-transp: rgba(245, 212, 191, 0.95);
  --cinza-fundo: #E5E5E5;
}

.pinyon-script-regular {
  font-family: "Pinyon Script", serif;
  font-weight: 400;
  font-style: normal;
}

.inter {
  font-family: "Inter", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.teko {
  font-family: "Teko", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.fundo-desfocado {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(10px);
  z-index: 10;
}

.sombra {
  box-shadow: 0 5px 4px 2px rgb(144, 144, 144, 0.8);
}

.botao_1 {
  background-color: #e7e2e1;
  color: #876148;
  padding: 0.3rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 0.5rem;
  border: 2px solid #958982;
  font-weight: 500;
  font-size: medium;
}

.botao_1 {
  background-color: black;
  color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-weight: bold;
  font-size: medium;
}

.botao_1_alt {
  background-color: white;
  color: black;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-weight: bold;
  font-size: medium;
}

.botao_2 {
  padding: 0.25rem;
  border-radius: 0.5rem;
  width: 100%;
  border: 2px solid #cecece;
}

.botao_2:hover {
  background-color: #dbdbdb;
}

.botao_3 {
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: #5c4643;
  width: 100%;
  height: 100%;
  border: 2px solid #c3c1c0;
  font-weight: 600;
  gap: 0.5rem;
}

.botao-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  margin-top: -0.75rem;
  width: 13%;
  border-width: 2px;
  border-color: black;
}

.input_1 {
  background-color: #d9d9d9;
  padding: 0.3rem;
  border-radius: 0.5rem;
  border: 2px solid #bfbfbf;
  margin-bottom: 15px;
}

.input_1::placeholder {
  color: #808080;
}

.input_2 {
  width: 100%;
  border-width: 2px;
  padding: 0.5rem;
  border: 2px solid #bfbfbf;
  border-radius: 0.5rem;
}

.input_3 {
  width: 100%;
  border-width: 1px;
  padding: 0.5rem;
  border: 2px solid #000000;
  border-radius: 0.3rem;
}

/* Estilo padrão para desktop */
.grid_livro {
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(auto-fit, minmax(40px, 300px));
  margin-top: 3rem;
} 

.grid_paginas {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(120px, 120px));
  margin-top: 3rem;
}

.sombra {
  box-shadow: -8px 8px 10px rgba(0, 0, 0, 0.7);
}

/* Estilo para telas menores (mobile) */
@media (max-width: 1000px) {
  .grid_livro {
    grid-template-columns: repeat(auto-fit, minmax(400px, 420px));
  }
}

/* Estilo para telas menores (mobile) */
@media (max-width: 1000px) {
  .grid_paginas {
    grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
  }
}

.custom-checkbox {
  appearance: none; 
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 20px;
  width: 20px;
  border: 2px solid none; 
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  outline: none;
  background-color: #fff;
  transition: background-color 0.3s ease;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5), 0 0px 4px 0 rgba(0, 0, 0, 0.19);
}

.custom-checkbox:checked {
  background-color: gray;
  border-color: gray;
}

.custom-checkbox:checked::after {
  content: '✔️';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: white;
}

@keyframes shake-alerta {
  0% { transform: translate(0); }
  25% { transform: translate(5px); }
  50% { transform: translate(-5px); }
  75% { transform: translate(5px); }
  100% { transform: translate(0); }
}

.shake-animation {
  animation: shake-alerta 0.5s ease;
}

.custom-link {
    color: blue;
    text-decoration: underline;
}

.custom-link:visited {
    color: darkblue;
}